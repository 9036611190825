import qs from 'qs';

const CONFIG = {};
CONFIG.getHistoryTrackByTravelId = '/v3/u/travel/getById';
var url = window.location.href;

if( ENV === 'dev' ){//开发/测试
    // CONFIG.SERVER_ROOT = 'http://api-dev.spreadwin.cn';
    CONFIG.SERVER_ROOT = '//api-dev.spreadwin.cn';
}else if( ENV === 'local' ){//本地
    CONFIG.SERVER_ROOT = '//api.spreadwin.cn';
}else if( ENV === 'bingo' ){//bingo本地
    CONFIG.SERVER_ROOT = 'http://bingo.spreadwin.cn:4000';
    CONFIG.SERVER_ROOT = '//api-dev.spreadwin.cn';
}else{//正式
    CONFIG.SERVER_ROOT = '//api.spreadwin.cn';
}

const typeMap = {
    1: '直播历史',
    2: '云记录仪视频',
    3: '语音抓拍图片', // 语音抓拍图片（有可能是车机上手动点按钮拍照）
    4: '语音抓拍视频', // 语音抓拍视频（有可能是车机上手动点按钮拍照）
    5: '行车碰撞图片',
    6: '行车碰撞视频',
    7: '实时拍照',
    8: '实时录像',
    9: '停车照片',
    10: '停车视频',
    11: '休眠震动报警图片',
    12: '休眠震动报警视频',
    13: 'SOS图片',
    14: 'SOS视频',
    15: '行车碰撞图片', // (斑马)
    16: '行车碰撞视频', // (斑马)
    17: '休眠震动报警图片', // (斑马)
    18: '休眠震动报警视频', // (斑马)
    100: '行程报告',
    101: '斑马附件',
};
export {CONFIG, typeMap};



export function sec2str(sec){
    if( sec < 60 ){
        return "00:00:"+ (sec < 10 ? "0" : '') + sec;
    }
    var second = sec % 60;
    var min = Math.floor(sec/60);
    if( min < 60 ){
        return "00:" + (min < 10 ? "0" : '') + min + ":" + (second < 10 ? "0" : '') + second;
    }
    var minute = min % 60;
    var hour = Math.floor( min/60 );
    return (hour < 10 ? "0" : '') + hour+ ":"  + (minute < 10 ? "0" : '') + minute + ":" + (second < 10 ? "0" : '') + second;
}

export function sec2strTwo(sec){
  if( sec < 60 ){
      return `${sec}<span>秒</span>`;
  }
  var second = sec % 60;
  var min = Math.floor(sec/60);
  if( min < 60 ){
      return `${min}<span>分</span>${second}<span>秒</span>`;
  }
  var minute = min % 60;
  var hour = Math.floor( min/60 );
  return `${hour}<span>时</span>${minute}<span>分</span>`;
}
export function Date2str( d ){
    return [ d.getFullYear(), '|-', d.getMonth()+1, '|-', d.getDate(), '| ', d.getHours(), '|:', d.getMinutes(), '|:', d.getSeconds() ].join('').replace(/([^\d])(\d)([^\d]|$)/g, '$1'+0+'$2$3').replace(/\|/g, '');
}

export function setAxios( axios ){
    axios.interceptors.request.use(
        function( config ){
            // axios.defaults.headers['Authorization'] = newToken;
            // console.log( "TOKEN2:", newToken, oldToken, config );
            if( config.method === 'post' ){
                // console.error( "TOKEN3:", newToken, oldToken );
                config.data = {
                    ...config.data,
                    // token: newToken,
                };
                // config.data = qs.stringify(config.data);
                config.params = {
                    // customer: customer,
                    ...config.params,
                }
                // console.error(config.data);
                // config.data = JSON.stringify(config.data);
            }else if( config.method === 'get' ){
                config.params = {
                    // customer:customer,
                    ...config.params,
                    // token: newToken,
                }
            }
            return config;
        }
    );
}



// 浏览器判断
let checkBrowser = {
    // 判断是否是微信浏览器
    isWx () {
        // 取得浏览器的userAgent字符串
        // let userAgent = navigator.userAgent;
        let ua = navigator.userAgent.toLowerCase();
        return (/micromessenger/i.test(ua))
    },
    // 判断是否是ios系统
    isIOS () {
        let u = navigator.userAgent;
        // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    }
};
export {checkBrowser};



// export default {
//     CONFIG,
//     sec2str,
// };
